import {
  json,
  type LoaderFunctionArgs,
  type ActionFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction,
} from '@remix-run/node'
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react'
import tailwindStyleSheetUrl from '#app/styles/tailwind.css?url'
import fontsStyleSheetUrl from '#app/styles/satoshi.css?url'
import { getUserId, logout } from '#app/utils/auth.server.ts'
import {
  ClientHintCheck,
  getHints,
  useHints,
} from '#app/utils/client-hints.tsx'
import { useNonce } from '#app/utils/nonce-provider.ts'
import { useOptionalUser, useUser } from '#app/utils/user.ts'
import { combineHeaders, getDomainUrl } from '#app/utils/misc.tsx'
import { getEnv } from '#app/utils/env.server.ts'
import { userInitials } from '#app/utils/user.ts'
import { db } from '#app/utils/drizzle.server.ts'
import { users } from '#drizzle/schema'
import { eq } from 'drizzle-orm'

import { Dialog, Menu, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { cn } from '#app/utils/misc.tsx'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { honeypot } from '#app/utils/honeypot.server.ts'

import { withSentry } from '@sentry/remix'

import {
  Home,
  Cog,
  X,
  ChevronDown,
  Search,
  Menu as Hamburger,
  PersonStanding,
  Shirt,
  ShoppingCart,
} from 'lucide-react'
import LogoSymbol from './components/LogoSymbol'
import Logotype from './components/logotype'
import LouvelleLogo from './components/logo'
import { makeTimings, time } from './utils/timing.server.ts'
import { getToast } from './utils/toast.server.ts'
import { Button } from './components/ui/button.tsx'
import { invariant } from '@epic-web/invariant'

export const links: LinksFunction = () => {
  return [
    // load the google fonts
    {
      rel: 'preconnect',
      href: 'https://fonts.googleapis.com',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: 'anonymous',
    } as const, // necessary to make typescript happy
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Marcellus&display=swap',
    },
    {
      rel: 'alternate icon',
      type: 'image/png',
      href: '/favicons/favicon-32x32.png',
    },
    { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
      crossOrigin: 'use-credentials',
    } as const,
    //These should match the css preloads above to avoid css as render blocking resource
    // { rel: "icon", type: "image/svg+xml", href: "/favicons/favicon.svg" },
    { rel: 'stylesheet', href: fontsStyleSheetUrl },
    { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  ].filter(Boolean)
}

export const meta: MetaFunction = () => {
  return [
    { title: 'Louvelle' },
    { name: 'description', content: 'The New Rotation' },
  ]
}

export async function loader({ request }: LoaderFunctionArgs) {
  const stage = process.env.SST_STAGE
  const timings = makeTimings('root loader')
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root',
  })

  const user = userId
    ? await time(
      () =>
        db.query.users.findFirst({
          where: eq(users.id, userId),
          columns: {
            id: true,
            name: true,
            username: true,
          },
        }),
      { timings, type: 'find user', desc: 'find user in root' },
    )
    : null
  if (userId && !user) {
    console.info('something weird happened')
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' })
  }
  const { toast, headers: toastHeaders } = await getToast(request)
  const honeyProps = honeypot.getInputProps()

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {},
      },
      ENV: getEnv(),
      toast,
      honeyProps,
      stage,
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders,
      ),
    },
  )
}

function Document({
  children,
  nonce,
  env = {},
}: {
  children: React.ReactNode
  nonce: string
  env?: Record<string, string>
}) {
  return (
    <html lang="en" className="h-full overflow-x-hidden">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
        <Meta />
        <Links />
      </head>
      <body className="min-h-[100svh] border-0 border-dashed border-red-500">
        {children}
        <ScrollRestoration />
        <Scripts />
        {/* <LiveReload /> */}
      </body>
    </html>
  )
}

const navigation = [
  { name: 'Dashboard', href: '/', icon: Home, current: false },
  {
    name: 'Users',
    href: '/users?role=customer',
    icon: PersonStanding,
    current: false,
  },
  {
    name: 'Items',
    href: '/items',
    icon: Shirt,
    current: false,
  },
  {
    name: 'Looks',
    href: '/looks',
    icon: Shirt,
    current: false,
  },
  {
    name: 'Designers',
    href: '/designers',
    icon: PersonStanding,
    current: false,
  },
  {
    name: 'Stripe',
    href: '/stripe',
    icon: ShoppingCart,
    current: false,
  },
  {
    name: 'Admin Configuration',
    href: '/settings',
    icon: Cog,
    current: false,
  },
]

function AppWithProviders() {
  const data = useLoaderData<typeof loader>()
  return (
    <HoneypotProvider {...data.honeyProps}>
      <App />
    </HoneypotProvider>
  )
}

export default withSentry(AppWithProviders)

function App() {
  const data = useLoaderData<typeof loader>()
  const user = data.user
  const loggedInUser = useOptionalUser()
  const nonce = useNonce()

  const [sidebarOpen, setSidebarOpen] = useState(false)

  // TODO: use user role to determine menu options
  const userNavigation = [
    { name: 'Your profile', href: `/users/${user?.username}` },
    { name: 'Sign out', href: '/logout' },
  ]

  return (
    <Document nonce={nonce} env={data.ENV}>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <X className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-eggplant-700 px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <Link to="/">
                      <LouvelleLogo color="#ffffff" className="w-[90%] pt-4" />
                    </Link>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <a
                                href={item.href}
                                className={cn(
                                  item.current
                                    ? 'bg-eggplant-500 text-white'
                                    : 'text-indigo-200 hover:bg-eggplant-500 hover:text-white',
                                  'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                )}
                              >
                                <item.icon
                                  className={cn(
                                    item.current
                                      ? 'text-white'
                                      : 'text-indigo-200 group-hover:text-white',
                                    'h-6 w-6 shrink-0',
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li className="mt-auto">
                        <a
                          href="#"
                          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-eggplant-700 hover:text-white"
                        >
                          <Cog
                            className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                            aria-hidden="true"
                          />
                          Settings
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-eggplant-700 px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <Link to="/">
              <LouvelleLogo color="#ffffff" className="w-[90%] pt-4" />
            </Link>
          </div>
          <nav className="flex flex-1 flex-col text-white">
            {loggedInUser || true ? (
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          reloadDocument
                          className={cn(
                            item.current
                              ? 'bg-eggplant-400 text-white'
                              : 'text-white hover:bg-eggplant-500 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          )}
                        >
                          <item.icon
                            className={cn(
                              item.current
                                ? 'text-white'
                                : 'text-white group-hover:text-white',
                              'h-6 w-6 shrink-0',
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a
                    href="#"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:bg-eggplant-700 hover:text-white"
                  >
                    <Cog
                      className="h-6 w-6 shrink-0 text-white group-hover:text-white"
                      aria-hidden="true"
                    />
                    Settings
                  </a>
                </li>
              </ul>
            ) : null}
          </nav>
        </div>
      </div>

      <div className="lg:pl-72">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Hamburger className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div
            className="h-6 w-px bg-gray-900/10 lg:hidden"
            aria-hidden="true"
          />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div className="relative flex flex-1">
              {loggedInUser ? (
                <form className="relative flex flex-1" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <Search
                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    type="search"
                    name="search"
                  />
                </form>
              ) : null}
            </div>

            <div className="flex items-center gap-x-4 lg:gap-x-6">
              {/* <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

              {/* Separator */}
              <div
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                aria-hidden="true"
              />

              {/* Profile dropdown */}
              {loggedInUser ? (
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                      <span className="font-medium leading-none text-white">
                        {userInitials(user?.name || '?')}
                      </span>
                    </span>
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        {user?.name}
                      </span>
                      <ChevronDown
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={cn(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900',
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                <Link to="/login">
                  <p className="text-xl">Log In</p>
                </Link>
              )}
            </div>
          </div>
        </div>

        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>
    </Document>
  )
}
